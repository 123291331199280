/* eslint-disable import/no-anonymous-default-export */
export default {
	header: {
		self: {},
		items: [
		]
	},
	aside: {
		self: {},
		items: [
			{
				title: "Dashboard",
				root: true,
				icon: "fas fa-tachometer-alt",
				page: "dashboard",
				bullet: "dot"
			},
			{
				title: "Confirmação Pagamentos",
				root: true,
				page: "pix",
				icon: "fas fa-money-bill-wave",
				bullet: "dot",
			},
			{
				title: "Setores",
				root: true,
				icon: "fas fa-map-pin",
				page: "setor",
				bullet: "dot"
			},
			{
				title: "Serviços",
				root: true,
				icon: "fas fa-tools",
				bullet: "dot",
				submenu: [
					{
						title: "Limpeza",
						bullet: "dot",
						page: "servico/limpeza"
					},
					{
						title: "Estacionamento",
						bullet: "dot",
						page: "servico/estacionamento"
					},
					{
						title: "Classificação de produto",
						bullet: "dot",
						page: "servico/classificacao-produto"
					},
				]
			},
			{
				title: "Agendamentos",
				root: true,
				bullet: "dot",
				icon: "fas fa-clock",
				submenu: [
					{
						title: "Limpeza",
						bullet: "dot",
						page: "agendamentos/limpeza"
					},
					{
						title: "Estacionamento",
						bullet: "dot",
						page: "agendamentos/estacionamento"
					},
					{
						title: "Classificação de produto",
						bullet: "dot",
						page: "agendamentos/classificacao-produto"
					},
					{
						title: "Triagem",
						bullet: "dot",
						page: "agendamentos/triagem"
					},
				]
			},
			{
				title: "Vagas Mensais",
				root: true,
				icon: "fas fa-calendar-plus",
				page: "vaga-mensal",
				bullet: "dot"
			},
			{
				title: "Cupom de desconto",
				root: true,
				icon: "fas fa-tag",
				page: "cupom",
				bullet: "dot"
			},
			{
				title: "Desconto",
				root: true,
				icon: "fas fa-percentage",
				page: "desconto",
				bullet: "dot"
			},
			{
				title: "Relatórios",
				root: true,
				bullet: "dot",
				icon: "fas fa-chart-pie",
				submenu: [
					{
						title: "Financeiro",
						bullet: "dot",
						page: "relatorio/relatorio01"
					},
					{
						title: "Cobrança",
						bullet: "dot",
						page: "relatorio/relatorio02"
					},
				]
			},
			{
				title: "Usuários",
				root: true,
				icon: "fas fa-user-shield",
				page: "usuario",
				bullet: "dot"
			},
			{
				title: "Grupos de Acesso",
				root: true,
				icon: "fas fa-layer-group",
				page: "group",
				bullet: "dot"
			},
			{
				title: "Cadastro motorista",
				root: true,
				icon: "fas fa-plus",
				page: "motorista/cadastro-motorista",
				bullet: "dot"
			},
		]
	}
};
