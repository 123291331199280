/* eslint-disable import/no-anonymous-default-export */
export default {
	header: {
		self: {},
		items: [
		]
	},
	aside: {
		self: {},
		items: [
			{
				title: "Agendamento",
				root: true,
				icon: "fas fa-tachometer-alt",
				page: "resumo",
				bullet: "dot"
			},
	
		]
	}
};
