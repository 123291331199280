import React, { Suspense, lazy } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { LayoutSplashScreen } from "../../../_metronic";
import { connect } from 'react-redux';

const DashboardPage = lazy(() =>
	import("./dashboard/DashboardPage")
);

const GroupPage = lazy(() =>
	import("./group/GroupPage")
);

const UsuarioPage = lazy(() =>
	import("./usuario/UsuarioPage")
);

const TriagemPage = lazy(() =>
	import("./triagem/TriagemPage")
);

const LimpezaPage = lazy(() =>
	import("./limpeza/LimpezaPage")
);

const EstacionamentoPage = lazy(() =>
	import("./estacionamento/EstacionamentoPage")
);

const ClassificacaoProdutoPage = lazy(() =>
	import("./classificacao-produto/ClassificacaoProdutoPage")
);

const CupomDescontoPage = lazy(() =>
	import("./cupom-desconto/CupomDescontoPage")
);

const ServicoEstacionamentoPage = lazy(() =>
	import("./servico-estacionamento/ServicoEstacionamentoPage")
);

const ServicoLimpezaPage = lazy(() =>
	import("./servico-limpeza/ServicoLimpezaPage")
);

const ServicoClassificacaoProdutoPage = lazy(() =>
	import("./servico-classificacao-produto/ServicoClassificacaoProdutoPage")
);

const SetorPage = lazy(() =>
	import("./setor/SetorPage")
);

const VagaMensalPage = lazy(() =>
	import("./vaga-mensal/VagaMensalPage")
);

const DescontoPage = lazy(() =>
	import("./desconto/DescontoPage")
);

const RelatorioPage = lazy(() =>
	import("./relatorio/RelatorioPage")
);

const ConfirmacaoPixPage = lazy(() =>
	import("./pix/ConfirmacaoPixPage")
);

const MotoristaPage = lazy(() =>
	import("./motorista/MotoristaPage")
);

const ResumePage = lazy(() =>
	import("./resumo/ResumePage")
);

function HomePage({user}) {

	return (
		<Suspense fallback={<LayoutSplashScreen />}>
			<Switch>
	
				{user?.empresa_tipo.toLowerCase() === "usina" ?
				
				<>
					<Route path="/resumo" component={ResumePage} /> 
				</>
				
				: 
				<>
					{
						<Redirect exact from="/" to="/dashboard" />
					}

					<Route path="/dashboard" component={DashboardPage} />
					<Route path="/group" component={GroupPage} />
					<Route path="/usuario" component={UsuarioPage} />
					<Route path="/servico/limpeza" component={ServicoLimpezaPage} />
					<Route path="/servico/estacionamento" component={ServicoEstacionamentoPage} />
					<Route path="/servico/classificacao-produto" component={ServicoClassificacaoProdutoPage} />
					<Route path="/setor" component={SetorPage} />
					<Route path="/vaga-mensal" component={VagaMensalPage} />
					<Route path="/cupom" component={CupomDescontoPage} />
					<Route path="/desconto" component={DescontoPage} />
					<Route path="/relatorio" component={RelatorioPage} />
					<Route path="/agendamentos/limpeza" component={LimpezaPage} />
					<Route path="/agendamentos/classificacao-produto" component={ClassificacaoProdutoPage} />
					<Route path="/agendamentos/estacionamento" component={EstacionamentoPage} />
					<Route path="/agendamentos/triagem" component={TriagemPage} />
					<Route path="/operacoes/estacionamento" component={EstacionamentoPage} />
					<Route path="/operacoes/classificacao-produto" component={ClassificacaoProdutoPage} />
					<Route path="/operacoes/limpeza" component={LimpezaPage} />
					<Route path="/pix" component={ConfirmacaoPixPage} />
					<Route path="/motorista/cadastro-motorista" component={MotoristaPage} />
					<Route path="/resumo" component={ResumePage} />

					{/* <Redirect to="/error/error-v6" /> */}
				</>

				}
	
			</Switch>
		</Suspense>
	);
}

const mapStateToProps = ({ auth: { user } }) => ({
	user,

  });
  
  export default connect(mapStateToProps)(HomePage);
